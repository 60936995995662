import enTranslation from '../lang/en.json';
import hiTranslation from '../lang/hindi.json';
import arTranslation from '../lang/ar.json';

export default {
    en: {
        name: "en",
        text: "English",
        langJson: enTranslation
    },
    ar: {
        name: "ar",
        text: "مصر",
        langJson: arTranslation
    }
};
