/**
 * 本地化配置
 * 配置文字的本地化，包括语言、货币、图片的path等
 *
 */

export default {
  locationKey: "EG",
  currencyCode: "EGP",
  currencyName: "Egyptian Pound",
  gameName: "GameLaka",
  appVersion: "1.1.1",
  forceUpdateToVersion: "1.1.1",
  companyName: "GAMELAKA",
  licence: "No.0000357",
  availableCountryList: ["EG"],
  icon: require("@/assets/icon.png").default,
  appIcon: require("@/assets/app_icon.png").default,
  appIcon64: require("@/assets/app_icon_64x64.png").default,
  appIconRound: require("@/assets/icon_round.png").default,
  appIconRound64: require("@/assets/icon_round_64x64.png").default,
  titleImg: require("@/assets/images/header/fz_char_logo.png").default,
  titleImgWhite: require("@/assets/images/header/fz_char_logo_white.png").default,
  indexBannerActivityImg: "index_banner_activity",
  indexBannerHarvestImg: require("@/assets/images/home/index_banner_harvest_in.png").default,
  indexBannerDepositImg: "index_banner_deposit_pk",
  indexBannerDeposit2Img: "index_banner_deposit2_in",
  indexBannerDrawImg: "index_banner_draw",
  indexBannerinviteImg: "index_banner_invite_pk_1",
  indexBannerLuckyWheelImg: "index_banner_lucky_wheel_pk",
  indexBannerInstallImg: "index_banner_install_pk",
  indexBannerLeaderImg: "index_banner_leader_in",
  indexBannerFreeCashImg: "index_banner_freecash_pk",
  downloadTipTextImg: "top_zi_pk",
  referFriendsPopup: require("@/assets/images/home/ing_2_in.png").default,
  freeCashPopup: "popup_freecash_pk",
  registerDownloadAd: "reg_download_pk",
  downloadFloatImg: require("@/assets/images/download/float-download-pk.png").default,
  luckyWheelShareImg: "/assets/images/lucky_wheel/banner_x2_in.png",
};
